.c-profile-crawl-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 62px;
    width: 100%;
}

.c-profile-crawl-container__right {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.c-user-profile-container {
    display: flex;
}

.c-user-profile-container--profile-placeholder {
    height: 85px;
    width: 242px;
}

.c-user-profile__img-link,
.c-user-profile__img-link:hover {
    align-items: center;
    display: flex;
    justify-content: center;
}

.c-user-profile__img {
    border: 2px solid white;
    border-radius: 50%;
    height: 85px;
    margin-right: 0px;
    transition: transform 0.25s linear, margin-right 0.25s linear;
    width: 85px;
}

.c-user-profile__img:hover {
    transform: scale(1.15, 1.15);
    transition: transform 0.25s linear, margin-right 0.25s linear;
    margin-right: 4px;
}

.c-user-handles {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 14px;
}

.c-user-handles__username {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: var(--secondary_font_family);
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    margin-bottom: 4px;
}

.c-user-profile__username--link,
.c-user-profile__username--link:hover {
    font-family: var(--secondary_font_family);
    font-size: 18px;
    font-weight: bold;
    text-decoration: none !important;
}

.c-user-handles__icon {
    margin-left: 8px;
}

.c-user-handles__icon--real,
.c-user-handles__icon--fake {
    margin-top: 0.5px;
}

.c-user-handles__full-name {
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 400;
}

.c-user-stats {
    align-items: center;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.06);
    box-shadow: 0 1px 4px rgba(100, 100, 100, 0.02), 0 1px 4px rgba(100, 100, 100, 0.03);
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: flex-end;
    max-width: 400px;
}

.c-user-stats__stat {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100px;
}

.c-user-stats__count {
    font-family: var(--primary_font_family);
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 6px;
}

.c-user-stats__count-loading {
    color: var(--grey);
}

.c-user-stats__label {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
}

.c-user-stats__label--loading {
    color: var(--dark_grey);
}


@media only screen and (max-width: 1000px) { 

    .c-user-profile-container {
        margin-bottom: 32px;
    }
    
    .c-profile-crawl-container__right {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 975px) { 

    .c-profile-crawl-container {
        justify-content: flex-start;
    }

    .c-user-profile-container--profile-placeholder {
        margin-bottom: 32px;
    }

    .c-user-profile-container,
    .c-user-stats {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 584px) { 

    .c-profile-crawl-container {
        justify-content: center;
    }

    .c-user-profile-container {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }

    .c-user-stats {
        max-width: 300px;
    }

    .c-user-stats__stat {
        width: 75px;
    }
}

@media only screen and (max-width: 520px) { 

    .c-profile-crawl-container__right {
        justify-content: center;
    }
}
    