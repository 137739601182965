.c-sticky-cover {
    background-color: var(--bg_color);
    height: 4px;
    position: sticky;
    margin: -1px;
    top: 86px;
    width: 100%;
    z-index: 3000;
}

@media only screen and (max-width: 975px) { 

    .c-sticky-cover {
        margin-top: -1px;
        top: 92px;
    }
}

@media only screen and (max-width: 726px) { 

    .c-sticky-cover {
        margin-top: -1px;
        top: 86px;
    }
}