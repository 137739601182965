.c-profile-crawls-container {
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.06);
    box-shadow: 0 1px 4px rgba(100, 100, 100, 0.02), 0 1px 4px rgba(100, 100, 100, 0.03);
    padding: 12px 12px 0 12px;
}

.c-profile-crawls__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
}

.c-profile-crawls__header-title {
    color: var(--black);
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 500;
}

.c-profile-crawls-table__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 0px;
}

.c-profile-crawls-table {
    padding: 0 0 12px 0;
}

.c-profile-crawls-table--mobile {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 !important;
}

.c-profile-crawls-table__title {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
}

.c-profile-crawls-table__title--user {
    flex-basis: 24%;
    padding-left: 8px;
}

.c-profile-crawls-table__title--real {
    flex-basis: 4%;
}

.c-profile-crawls-table__title--relevancy {
    flex-basis: 20%;
}

.c-profile-crawls-table__title--followers {
    flex-basis: 12%;
    text-align: center;
}

.c-profile-crawls-table__title--following {
    flex-basis: 10%;
    text-align: center;
}

.c-profile-crawls-table__title--ratio {
    flex-basis: 8%;
    text-align: center;
}

.c-profile-crawls-table__title--posts {
    flex-basis: 10%;
    text-align: center;
}

.c-profile-crawls-table__title--private,
.c-profile-crawls-table__title--verified {
    flex-basis: 6%;
    text-align: center;
}

@media only screen and (max-width: 1075px) { 

    .c-profile-crawls-container {
        max-width: 616px;
    }

    .c-profile-crawls-table--mobile {
        grid-template-columns: repeat(2, 1fr);
        max-width: 616px;
    }
}

@media only screen and (max-width: 740px) { 

    .c-profile-crawls-container {
        max-width: 308px;
    }

    .c-profile-crawls-table--mobile {
        grid-template-columns: repeat(1, 1fr);
        max-width: 308px;
    }
}

@media only screen and (max-width: 584px) { 

    .c-profile-crawls-container {
        max-width: 308px;
        margin-left: auto;
        margin-right: auto;
    }
}
