.c-profile-crawl-card {
    background-color: var(--bg_color);
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.02);
    height: 150px;
    margin-bottom: 32px;
    padding: 4px 12px 0 12px;
    width: 280px;
}

.c-profile-crawl-card__row {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: space-between;
}

.c-profile-crawl-card__row:nth-child(2) {
    background-color: #FDFDFD;
    border-radius: 4px;
    justify-content: flex-start;
    padding: 0 4px;
}

.c-profile-crawl-card__left-col {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.c-profile-crawl-card__left-col * {
    flex-grow: 0;
}

.c-profile-crawl-card__right-col {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.c-profile-crawl-card__right-col * {
    margin-left: 4px;
}

.c-profile-crawl-card__avatar {
    border-radius: 50%;
    border-color: 2px solid white;
    height: 30px;
    margin-right: 12px;
    width: 30px;
}

.c-profile-crawl-crawl__username {
    color: var(--black);
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
    vertical-align: middle;
}

.c-profile-card__stat {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.c-profile-card__stat-title {
    font-family: var(--secondary_font_family);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.c-profile-card__stat-label {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 4px;
}

.c-profile-card__stat--followers {
    flex-basis: 30%;
}

.c-profile-card__stat--following {
    flex-basis: 30%;
}

.c-profile-card__stat--ratio {
    flex-basis: 20%;
}

.c-profile-card__stat--posts {
    flex-basis: 20%;
}

