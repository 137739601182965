.c-profile-crawl-row {
    align-items: center;
    border: 2px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 50px;
    padding-left: 4px;
}

.c-profile-crawl-row * {
    margin-bottom: 0;
}

.c-profile-crawl-row span {
    color: white;
}

.c-profile-crawl-row:hover {
    border-radius: 4px;
    border: 1px solid var(--input_border_color_inactive);
}

.c-profile-crawl-row:first-child {
    margin-top: 10px;
}

.c-profile-crawl-row:nth-child(2n) {
    background-color: var(--light_grey);
}

.c-profile-crawl-col--avatar {
    align-items: center;
    display: flex;
    flex-direciton: row;
    justify-content: flex-start;
    flex-basis: 4%;
    padding-left: 2px;
}

.c-profile-crawl__profile-pic__link,
.c-profile-crawl__profile-pic__link:hover {
    align-items: center;
    display: flex;
    flex-direciton: row;
    justify-content: flex-start;
}

.c-profile-crawl__profile-pic {
    border-radius: 50%;
    border-color: 2px solid white;
    height: 30px;
    width: 30px;
}

.c-profile-crawl-col--username {
    color: var(--black);
    flex-basis: 20%;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
    padding-bottom: 3px;
    vertical-align: middle;
}

.c-profile-crawl-col--username:hover {
    text-decoration: none !important;
}

.c-profile-crawl-col--real {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-basis: 4%;
}

.c-profile-crawl-col--real .c-real,
.c-profile-crawl-col--real .c-fake {
    margin-left: 1px !important;
    margin-bottom: 1px !important;
} 

.c-profile-crawl-col--relevancy {
    flex-basis: 20%;
    margin-bottom: 0;
}

.c-profile-crawl-col--relevancy-mbl {
    flex-basis: 100%;
    height: 30px;
    width: 100%;
}

.c-profile-crawl-col--relevancy-progress-bar {
    background-color: var(--grey) !important;
    margin-bottom: 0 !important;
}

.c-profile-crawl-col--relevancy-progress-bar .bar {
    min-width: 0 !important;
    transition: width 1s ease, background-color 1s ease !important;
}

.c-profile-crawl-col--relevancy-progress-bar.background-color--green .bar {
    background-color: var(--green) !important;
}

.c-profile-crawl-col--relevancy-progress-bar.background-color--yellow .bar {
    background-color: var(--yellow) !important;
}

.c-profile-crawl-col--relevancy-progress-bar.background-color--orange .bar {
    background-color: var(--orange) !important;
}

.c-profile-crawl-col--relevancy-progress-bar.background-color--red .bar {
    background-color: var(--red) !important;
}

.c-profile-crawl-col--relevancy-progress-bar > .bar > .progress {
    color: white !important;
    left: 0 !important;
    margin-left: 5px;
}

.c-profile-crawl-col--count {
    flex-basis: 10%;
    font-family: var(--secondary_font_family);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    text-align: center;
}

.c-profile-crawl-col--count-followers {
    flex-basis: 12%;
}

.c-profile-crawl-col--count-ratio {
    flex-basis: 8%;
}

.c-profile-crawl-col--private,
.c-profile-crawl-col--verified {
    align-items: center;
    display: flex;
    flex-basis: 6%;
    justify-content: center;
}