.c-audit-results-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;
    margin-top: 40px;
}

.c-audit-results-card {
    align-items: center;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.06);
    box-shadow: 0 1px 4px rgba(100, 100, 100, 0.02), 0 1px 4px rgba(100, 100, 100, 0.03);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 188px;
    min-width: 236px;
    max-width: 236px;
    padding: 12px 12px 0 12px;
    position: relative;
    -webkit-animation: showCard 0.3s forwards;
    animation: showCard 0.3s forwards;
    opacity: 0;
}


@-webkit-keyframes showCard {
      0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes showCard {
      0% { opacity: 0; }
     100% { opacity: 1; }
}


.c-audit-results-card__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
}

.c-audit-results-card__title {
    color: var(--black);
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 500;
}

.c-audit-results-card__subtitle {
    top: 0;
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 12px;
    font-weight: 400;
    margin-top: 34px;
    letter-spacing: 0.5px;
    margin-left: 12px;
    position: absolute;
    line-height: 14px;
    text-align: left;
    width: 100%;
}

.c-audit-results-card__samples {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.c-audit-results-card__sample {
    align-items: center;
    background-color: var(--light_grey);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 105px;
    width: 105px;
}

.c-audit-results-card__sample--real {
    margin-right: 2px;
}

.c-audit-results-card__sample--fake {
    margin-left: 2px;
}

.c-audit-results-card__sample-size {
    font-family: var(--primary_font_family);
    font-size: 34px;
    font-weight: bold;
}

.c-audit-results-card__sample-size--real {
    color: var(--black);
}

.c-audit-results-card__sample-size--fake {
    color: var(--red);
}

.c-audit-results-card__sample-label {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
}

.c-audit-results-card--table {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.c-audit-results-card__row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
}

.c-audit-results-card__row:nth-child(2) {
    background-color: var(--light_grey);
}

.c-audit-results-card__stat-title {
    color: var(--black);
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 500;
}

.c-audit-results-card__stat-label {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
}

.c-audit-results-card__stat-value {
    font-family: var(--primary_font_family);
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
}

.c-audit-results-card__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    height: 188px;
    min-width: 236px;
    max-width: 236px;
}

@media only screen and (max-width: 1200px) { 

    .c-audit-results-container {
        justify-content: flex-start;
    }

    .c-audit-results-card__container {
        margin-bottom: 32px;
        margin-right: 32px;
    }

    .c-audit-results-card {
        margin-bottom: 32px;
        margin-right: 32px;
    }
}

@media only screen and (max-width: 655px) { 

    .c-audit-results-card__container {
        margin-bottom: 32px;
    }

    .c-audit-results-card__container:nth-child(2) {
        margin-bottom: 0px;
    }

    .c-audit-results-card  {
        margin-bottom: 32px;
    }

    .c-audit-results-card:nth-child(2) {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 584px) { 

    .c-audit-results-container {
        justify-content: center;
    }

    .c-audit-results-card__container {
        margin-left: 32px;
        margin-right: 32px;
    }

    .c-audit-results-card__container:nth-child(2) {
        margin-right: 32px;
    }

    .c-audit-results-card {
        margin-left: 32px;
        margin-right: 32px;
    }

    .c-audit-results-card:nth-child(2) {
        margin-right: 32px;
    }
}