.c-main-nav-container {
    background-color: var(--bg_color);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 3px rgba(100, 100, 100, 0.02), 0 1px 3px rgba(100, 100, 100, 0.03);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2000;
}

.c-main-nav {
    align-items: center;
    background-color: var(--bg_color);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 90px;
    justify-content: space-between;
}

.c-main-nav__section {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.c-main-nav__section--left {
    flex-grow: 1;
    justify-content: flex-start;
}

.c-main-nav__section--right {
    flex-grow: 0;
    justify-content: flex-end;
}

.c-main-nav__section--right .c-main-nav__item {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 2rem;
    transition: transform 0.25s ease, color 0.25s ease;
}

.c-main-nav__img {
    height: 40px;
    line-height: 35px;
    margin-right: 20px;
    vertical-align: middle;
}

.c-main-nav__icon {
    color: black;
    font-family: var(--secondary_font_family);
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
    text-decoration: none;
}

.c-main-nav__icon--close {
    color: var(--red);
}

.c-main-nav__button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-family: var(--secondary_font_family);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    outline: none;
    text-decoration: none;
}

.c-main-nav__button--close span {
    line-height: 20px;
    padding-top: 2px;
}

.c-main-nav__button:hover span {
    color: var(--yellow);
}

.c-main-nav__button--close,
.c-main-nav__button--close span {
    color: var(--white);
}

..c-main-nav__button--close span {}

.c-main-nav__button--close:hover span {
    color: var(--red) !important;
}

@media only screen and (max-width: 1075px) {

    .c-main-nav {
        justify-content: center;
    }

    .c-main-nav__section {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .c-main-nav__section--left {
        flex-basis: 100%;
        justify-content: center;
    }

    .c-main-nav__img {
        margin-bottom: 2rem;
        margin-top: 2rem;
        margin-right: 0;
        flex-basis: 100%;
    }

    .c-main-nav__section--right {
        background-color: var(--blue);
        height: 70px;
        order: 0;
        flex-basis: 100%;
        justify-content: center;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .c-main-nav__button {
        color: var(--white);
        height: 70px;
        width: 100%;
    }

    .c-main-nav__section--right .c-main-nav__item {
        margin-left: 0;
    }

    .c-main-nav__icon {
        color: var(--white);
    }
}

@media only screen and (max-width: 736px) { 

    .c-main-nav-container {
        position: sticky;
        top: 0px;
    }
}

@media only screen and (max-width: 726px) { 

    .c-main-nav-container {
        position: sticky;
        top: -75px;
    }

    .c-main-nav__img {
        margin-right: 0px;
        flex-basis: 100%;
    }

    .c-main-nav {
        padding-bottom: 25px;
    }
}