.c-search-bar-form {
    max-width: 450px;
    margin-left: 12px;
    width: 100%;
}

.c-search-bar-form__group {
    width: 100%;
}

.c-search-bar-form__container {
    position: relative;
}

.c-search-bar-form__input {
    border-radius: 24px;
    border: 1px solid var(--input_border_color_inactive);
    font-family: var(--secondary_font_family);
    font-size: 16px;
    font-weight: 400;
    height: 44px;
    line-height: 44px;
    outline: none;
    padding-left: 34px;
    padding-right: 70px;
    vertical-align: middle;
    width: 100%;
}

.c-search-bar-form__input::placeholder {
    color: var(--input_placeholder_color);
    font-family: var(--secondary_font_family);
    font-weight: 400;
    outline: none;
}

.c-search-bar-form__input:active,
.c-search-bar-form__input:focus,
.c-search-bar-form__input:hover {
    border: 1px solid var(--blue);
    outline: none;
}

.c-search-bar-form__button {
    background-color: white;
    border: none;
    border-radius: 19px;
    color: white !important;
    height: 36px;
    outline: none;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: background-color 0.15s ease;
    width: 36px;
}

.c-search-bar-form__button--invalid {
    background-color: var(--grey);
}

.c-search-bar-form__button--valid {
    background-color: var(--blue) !important;
}

.c-search-bar-form__button:hover {
    cursor: pointer;
}

.c-search-bar-form__icon--search {
    color: white;
    position: absolute;
    right: 10px;
    top: 10px;
}

.c-search-bar-form__icon--at {
    color: var(--input_placeholder_color);
    font-family: sans-serif;
    font-size: 18px;
    line-height: 44px;
    position: absolute;
    left: 14px;
    top: 0px;
    vertical-align: middle;
}

.c-search-bar-form__input:active ~ .c-search-bar-form__icon--at,
.c-search-bar-form__input:focus ~ .c-search-bar-form__icon--at,
.c-search-bar-form__input:hover ~ .c-search-bar-form__icon--at {
    color: var(--blue) !important;
}



.c-search-bar-form__icon--valid,
.c-search-bar-form__icon--invalid {
    position: absolute;
    right: 40px;
    top: 13px;
    width: 28px;
}

.c-search-bar-form__icon-container {
    display: none;
}

.c-search-bar-form__input:active ~ .c-search-bar-form__icon-container,
.c-search-bar-form__input:focus ~ .c-search-bar-form__icon-container {
    display: block !important;
}

.c-search-bar-form__icon--valid {
    color: var(--green) !important;
}

.c-search-bar-form__icon--invalid {
    color: var(--red) !important;
}

@media only screen and (max-width: 726px) {
    .c-search-bar-form {
        margin-left: 0;
    }
}