@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700%7CRoboto:300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Base */

:root {
    --black: #2E3A49;
    --blue: #0029FF;
    --red: #E4373F;
    --orange: #FF7702;
    --yellow: #FEDA01;
    --green: #01C58E;
    --grey: #DFE1E5;
    --dark_grey: #47494C;
    --light_grey: #FAFAFA;
    --medium_grey: #B6BAB9;
    --white: #FFF;
    --bg_color: var(--light_grey);
    --accent_color: black;
    --primary_text_color: var(--black);
    --accent_text_color: blue;
    --primary_font_size: 14px;
    --primary_font_family: 'Roboto Condensed', sans-serif;
    --secondary_font_family: 'Roboto', sans-serif;
    --input_placeholder_color: #979797;
    --input_border_color_inactive: #DFE1E5;
    --input_border_color_active: #F1F3F7;
}

/* Overrides */

* {
    box-sizing: border-box !important;
}

*::-webkit-scrollbar { width: 0 !important }

input[type=text],
input[type=username] {   
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

html,
body {
    background-color: black;
}

a {
    border-bottom: none;
    cursor: pointer;
    display: block;
}

a:active,
a:hover {
    background-color: transparent !important;
    border-bottom: none;
    color: #0029FF;
    color: var(--blue);
    display: block;
    text-decoration: underline !important;
}

a, p, h1, h2, h3, h4, h5, h6, span, input {
    color: #2E3A49;
    color: var(--primary_text_color);
    font-family: 'Roboto Condensed', sans-serif;
    font-family: var(--primary_font_family);
    font-size: 14px;
    font-size: var(--primary_font_size);
    font-weight: 400;
    margin: 0;
}

/* Container */

.l-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding-left: 80px;
    padding-right: 80px;
}

.l-container--inner {
    max-width: 1040px;
}

/* Misc */

.animation-delay-half-sec {
    -webkit-animation-delay: 0.5s !important;
            animation-delay: 0.5s !important;
}

.animation-delay-one-sec {
    -webkit-animation-delay: 1s !important;
            animation-delay: 1s !important;
}

.animation-delay-one-and-half-sec {
    -webkit-animation-delay: 1.5s !important;
            animation-delay: 1.5s !important;
}

.background-color--black {
    background-color: black !important;
}

.background-color--bg-color {
    background-color: #FAFAFA !important;
    background-color: var(--bg_color) !important;
}


.background-color--green {
    background-color: #01C58E !important;
    background-color: var(--green) !important;
}

.background-color--yellow {
    background-color: #FEDA01 !important;
    background-color: var(--yellow) !important;
}

.background-color--orange {
    background-color: #FF7702 !important;
    background-color: var(--orange) !important;
}

.background-color--red {
    background-color: #E4373F !important;
    background-color: var(--red) !important;
}

.background-color--white {
    background-color: #FFF !important;
    background-color: var(--white) !important;
}

.bold {
    font-weight: bold !important;
    letter-spacing: 0.5px;
}

.color-red {
    color: #E4373F !important;
    color: var(--red) !important;
}

.color-green {
    color: #01C58E;
    color: var(--green);
}

.color-insta-verified {
    color: rgb(55, 155, 235);
}

.color-red {
    color: #E4373F;
    color: var(--red);
}

.hidden {
    display: none;
}

.c-tooltip span {
    color: white !important;
    font-family: 'Roboto', sans-serif !important;
    font-family: var(--secondary_font_family) !important;
    font-size: 14px;
    font-weight: 300;
}

.c-exclamation-circle {
    color: #E4373F;
    color: var(--red);
}

.c-info-circle {
    color: #DFE1E5;
    color: var(--grey);
}

.c-info-circle:hover {
    color: #2E3A49;
    color: var(--black);
}

.c-check-circle {
    color: #01C58E;
    color: var(--green);
}

.c-times-circle {
    color: #E4373F;
    color: var(--red);
}

.c-lock {
    color: #2E3A49 !important;
    color: var(--black) !important;
}

.c-minus {
    color: #DFE1E5;
    color: var(--grey);
}

.c-verified {
    height: 18px;
    -webkit-transform: scale(0.0);
            transform: scale(0.0);
    width: 18px;
}

.c-real {
    color: #01C58E;
    color: var(--green);
    height: 18px;
    -webkit-transform: scale(0.0);
            transform: scale(0.0);
    width: 18px;
}

.c-fake {
    color: #E4373F;
    color: var(--red);
    height: 18px;
    -webkit-transform: scale(0.0);
            transform: scale(0.0);
    width: 18px;
}

.c-position--relative {
    position: relative !important;
}

.c-position--relative {
    position: relative !important;
}

.c-top--zero {
    top: 0 !important;
}

.c-padding-bottom--zero {
    padding-bottom: 0 !important;
}

.ticker {
    width: 100%;
}

/* Animations */


.pulse-on-display {
  -webkit-animation: pulse 0.3s ease-in forwards;
          animation: pulse 0.3s ease-in forwards;
  -webkit-animation-fill-mode: forward;
          animation-fill-mode: forward;
}

@-webkit-keyframes pulse {
    0%  {-webkit-transform: scale(0.0);transform: scale(0.0);}
   40%  {-webkit-transform: scale(0.8);transform: scale(0.8);}
   75%  {-webkit-transform: scale(1.3);transform: scale(1.3);}
  100%  {-webkit-transform: scale(1);transform: scale(1);}
}

@keyframes pulse {
    0%  {-webkit-transform: scale(0.0);transform: scale(0.0);}
   40%  {-webkit-transform: scale(0.8);transform: scale(0.8);}
   75%  {-webkit-transform: scale(1.3);transform: scale(1.3);}
  100%  {-webkit-transform: scale(1);transform: scale(1);}
}

/* Media Queries*/

@media only screen and (max-width: 900px) {
    .l-container {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media only screen and (max-width: 656px) {
    .l-container {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media only screen and (max-width: 400px) {
    .l-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.c-page {
    background-color: var(--bg_color);
    margin-top: -4px;
}

.c-page--audit {
    min-height: calc(100vh - 90px);
    width: 100%;
    z-index: 1000;
}

.c-page--index {
    min-height: calc(100vh - 90px - 42px);
    height: auto;
    padding-top: 4px;
    width: 100%;
}

.c-page--faq {
    background-color: black;
    min-height: calc(100vh - 90px);
    width: 100%;
}

.c-page--index > .l-container {
    align-items: flex-start;      /* OLD - iOS 6-, Safari 3.1-6 */         /* OLD - Firefox 19- (buggy but mostly works) */      /* TWEENER - IE 10 */     /* NEW - Chrome */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.c-page--loading {
    align-items: center;      /* OLD - iOS 6-, Safari 3.1-6 */         /* OLD - Firefox 19- (buggy but mostly works) */      /* TWEENER - IE 10 */     /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 90px);
    padding-bottom: 25%;
    width: 100%;
    z-index: 1000;
}

@media only screen and (max-width: 1100px) {
    .c-page--index {
        min-height: calc(100vh + 400px);
    }
    .c-page--index > .l-container {
        min-height: 0;
    }
}


.c-loading-page__title {
    align-items: center;
    color: var(--blue);
    display: flex;
    flex-direction: row;
    font-family: var(--secondary_font_family);
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 35px;
    text-align: center;
    justify-content: center;
    padding-bottom: 25%;
}

.c-loading-page__title * {
    color: var(--blue);
    font-family: var(--secondary_font_family);
    font-size: 17px;
    font-weight: 600;
}
.c-profile-crawl-card {
    background-color: var(--bg_color);
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.02);
    height: 150px;
    margin-bottom: 32px;
    padding: 4px 12px 0 12px;
    width: 280px;
}

.c-profile-crawl-card__row {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: space-between;
}

.c-profile-crawl-card__row:nth-child(2) {
    background-color: #FDFDFD;
    border-radius: 4px;
    justify-content: flex-start;
    padding: 0 4px;
}

.c-profile-crawl-card__left-col {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.c-profile-crawl-card__left-col * {
    flex-grow: 0;
}

.c-profile-crawl-card__right-col {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.c-profile-crawl-card__right-col * {
    margin-left: 4px;
}

.c-profile-crawl-card__avatar {
    border-radius: 50%;
    border-color: 2px solid white;
    height: 30px;
    margin-right: 12px;
    width: 30px;
}

.c-profile-crawl-crawl__username {
    color: var(--black);
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
    vertical-align: middle;
}

.c-profile-card__stat {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.c-profile-card__stat-title {
    font-family: var(--secondary_font_family);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.c-profile-card__stat-label {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 4px;
}

.c-profile-card__stat--followers {
    flex-basis: 30%;
}

.c-profile-card__stat--following {
    flex-basis: 30%;
}

.c-profile-card__stat--ratio {
    flex-basis: 20%;
}

.c-profile-card__stat--posts {
    flex-basis: 20%;
}


.c-profile-crawl-row {
    align-items: center;
    border: 2px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 50px;
    padding-left: 4px;
}

.c-profile-crawl-row * {
    margin-bottom: 0;
}

.c-profile-crawl-row span {
    color: white;
}

.c-profile-crawl-row:hover {
    border-radius: 4px;
    border: 1px solid var(--input_border_color_inactive);
}

.c-profile-crawl-row:first-child {
    margin-top: 10px;
}

.c-profile-crawl-row:nth-child(2n) {
    background-color: var(--light_grey);
}

.c-profile-crawl-col--avatar {
    align-items: center;
    display: flex;
    flex-direciton: row;
    justify-content: flex-start;
    flex-basis: 4%;
    padding-left: 2px;
}

.c-profile-crawl__profile-pic__link,
.c-profile-crawl__profile-pic__link:hover {
    align-items: center;
    display: flex;
    flex-direciton: row;
    justify-content: flex-start;
}

.c-profile-crawl__profile-pic {
    border-radius: 50%;
    border-color: 2px solid white;
    height: 30px;
    width: 30px;
}

.c-profile-crawl-col--username {
    color: var(--black);
    flex-basis: 20%;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
    padding-bottom: 3px;
    vertical-align: middle;
}

.c-profile-crawl-col--username:hover {
    text-decoration: none !important;
}

.c-profile-crawl-col--real {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-basis: 4%;
}

.c-profile-crawl-col--real .c-real,
.c-profile-crawl-col--real .c-fake {
    margin-left: 1px !important;
    margin-bottom: 1px !important;
} 

.c-profile-crawl-col--relevancy {
    flex-basis: 20%;
    margin-bottom: 0;
}

.c-profile-crawl-col--relevancy-mbl {
    flex-basis: 100%;
    height: 30px;
    width: 100%;
}

.c-profile-crawl-col--relevancy-progress-bar {
    background-color: var(--grey) !important;
    margin-bottom: 0 !important;
}

.c-profile-crawl-col--relevancy-progress-bar .bar {
    min-width: 0 !important;
    -webkit-transition: width 1s ease, background-color 1s ease !important;
    transition: width 1s ease, background-color 1s ease !important;
}

.c-profile-crawl-col--relevancy-progress-bar.background-color--green .bar {
    background-color: var(--green) !important;
}

.c-profile-crawl-col--relevancy-progress-bar.background-color--yellow .bar {
    background-color: var(--yellow) !important;
}

.c-profile-crawl-col--relevancy-progress-bar.background-color--orange .bar {
    background-color: var(--orange) !important;
}

.c-profile-crawl-col--relevancy-progress-bar.background-color--red .bar {
    background-color: var(--red) !important;
}

.c-profile-crawl-col--relevancy-progress-bar > .bar > .progress {
    color: white !important;
    left: 0 !important;
    margin-left: 5px;
}

.c-profile-crawl-col--count {
    flex-basis: 10%;
    font-family: var(--secondary_font_family);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    text-align: center;
}

.c-profile-crawl-col--count-followers {
    flex-basis: 12%;
}

.c-profile-crawl-col--count-ratio {
    flex-basis: 8%;
}

.c-profile-crawl-col--private,
.c-profile-crawl-col--verified {
    align-items: center;
    display: flex;
    flex-basis: 6%;
    justify-content: center;
}
.c-profile-crawls-container {
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.06);
    box-shadow: 0 1px 4px rgba(100, 100, 100, 0.02), 0 1px 4px rgba(100, 100, 100, 0.03);
    padding: 12px 12px 0 12px;
}

.c-profile-crawls__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
}

.c-profile-crawls__header-title {
    color: var(--black);
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 500;
}

.c-profile-crawls-table__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 0px;
}

.c-profile-crawls-table {
    padding: 0 0 12px 0;
}

.c-profile-crawls-table--mobile {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 !important;
}

.c-profile-crawls-table__title {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
}

.c-profile-crawls-table__title--user {
    flex-basis: 24%;
    padding-left: 8px;
}

.c-profile-crawls-table__title--real {
    flex-basis: 4%;
}

.c-profile-crawls-table__title--relevancy {
    flex-basis: 20%;
}

.c-profile-crawls-table__title--followers {
    flex-basis: 12%;
    text-align: center;
}

.c-profile-crawls-table__title--following {
    flex-basis: 10%;
    text-align: center;
}

.c-profile-crawls-table__title--ratio {
    flex-basis: 8%;
    text-align: center;
}

.c-profile-crawls-table__title--posts {
    flex-basis: 10%;
    text-align: center;
}

.c-profile-crawls-table__title--private,
.c-profile-crawls-table__title--verified {
    flex-basis: 6%;
    text-align: center;
}

@media only screen and (max-width: 1075px) { 

    .c-profile-crawls-container {
        max-width: 616px;
    }

    .c-profile-crawls-table--mobile {
        grid-template-columns: repeat(2, 1fr);
        max-width: 616px;
    }
}

@media only screen and (max-width: 740px) { 

    .c-profile-crawls-container {
        max-width: 308px;
    }

    .c-profile-crawls-table--mobile {
        grid-template-columns: repeat(1, 1fr);
        max-width: 308px;
    }
}

@media only screen and (max-width: 584px) { 

    .c-profile-crawls-container {
        max-width: 308px;
        margin-left: auto;
        margin-right: auto;
    }
}

.c-share-button-container {
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--blue);
    box-shadow: 0 2px 5px rgba(100, 100, 100, 0.04), 0 2px 5px rgba(100, 100, 100, 0.05);
    height: 78px;
    margin-left: 12px;
    width: 85px;
}

.c-share-button-container:hover {
    border-color: var(--black);
}

.c-share-button {
    background-color: var(--white);
    border-radius: 8px;
    border: none;
    color: var(--blue);
    cursor: pointer;
    height: 100%;
    outline: none;
    padding-top: 4px;
    width: 100%;
}

.c-share-button:hover {
    border-color: black;
    color: var(--black);
}

.c-share-button:hover * {
    color: var(--black);
}

.c-share-button__inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.c-share-button__title {
    color: var(--blue);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 10px;
}

@media only screen and (max-width: 520px) { 

    .c-share-button-container {
        flex-basis: 100%;
        margin-left: 0px; 
        margin-right: 0px; 
        margin-top: 32px; 
        max-width: 300px;
    }
}
.c-profile-crawl-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 62px;
    width: 100%;
}

.c-profile-crawl-container__right {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.c-user-profile-container {
    display: flex;
}

.c-user-profile-container--profile-placeholder {
    height: 85px;
    width: 242px;
}

.c-user-profile__img-link,
.c-user-profile__img-link:hover {
    align-items: center;
    display: flex;
    justify-content: center;
}

.c-user-profile__img {
    border: 2px solid white;
    border-radius: 50%;
    height: 85px;
    margin-right: 0px;
    -webkit-transition: margin-right 0.25s linear, -webkit-transform 0.25s linear;
    transition: margin-right 0.25s linear, -webkit-transform 0.25s linear;
    transition: transform 0.25s linear, margin-right 0.25s linear;
    transition: transform 0.25s linear, margin-right 0.25s linear, -webkit-transform 0.25s linear;
    width: 85px;
}

.c-user-profile__img:hover {
    -webkit-transform: scale(1.15, 1.15);
            transform: scale(1.15, 1.15);
    -webkit-transition: margin-right 0.25s linear, -webkit-transform 0.25s linear;
    transition: margin-right 0.25s linear, -webkit-transform 0.25s linear;
    transition: transform 0.25s linear, margin-right 0.25s linear;
    transition: transform 0.25s linear, margin-right 0.25s linear, -webkit-transform 0.25s linear;
    margin-right: 4px;
}

.c-user-handles {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 14px;
}

.c-user-handles__username {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: var(--secondary_font_family);
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    margin-bottom: 4px;
}

.c-user-profile__username--link,
.c-user-profile__username--link:hover {
    font-family: var(--secondary_font_family);
    font-size: 18px;
    font-weight: bold;
    text-decoration: none !important;
}

.c-user-handles__icon {
    margin-left: 8px;
}

.c-user-handles__icon--real,
.c-user-handles__icon--fake {
    margin-top: 0.5px;
}

.c-user-handles__full-name {
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 400;
}

.c-user-stats {
    align-items: center;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.06);
    box-shadow: 0 1px 4px rgba(100, 100, 100, 0.02), 0 1px 4px rgba(100, 100, 100, 0.03);
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: flex-end;
    max-width: 400px;
}

.c-user-stats__stat {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100px;
}

.c-user-stats__count {
    font-family: var(--primary_font_family);
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 6px;
}

.c-user-stats__count-loading {
    color: var(--grey);
}

.c-user-stats__label {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
}

.c-user-stats__label--loading {
    color: var(--dark_grey);
}


@media only screen and (max-width: 1000px) { 

    .c-user-profile-container {
        margin-bottom: 32px;
    }
    
    .c-profile-crawl-container__right {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 975px) { 

    .c-profile-crawl-container {
        justify-content: flex-start;
    }

    .c-user-profile-container--profile-placeholder {
        margin-bottom: 32px;
    }

    .c-user-profile-container,
    .c-user-stats {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 584px) { 

    .c-profile-crawl-container {
        justify-content: center;
    }

    .c-user-profile-container {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }

    .c-user-stats {
        max-width: 300px;
    }

    .c-user-stats__stat {
        width: 75px;
    }
}

@media only screen and (max-width: 520px) { 

    .c-profile-crawl-container__right {
        justify-content: center;
    }
}
    
.c-pie-chart-container {
    position: relative;
    margin-bottom: 10px !important;
}

.c-pie-chart__value-container {
    align-items: center;
    bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 100%;
}

.c-pie-chart__value {
    font-family: var(--primary_font_family);
    font-size: 60px;
    font-weight: bold;
    margin-left: 8px;
}

.c-pie-chart__percent {
    font-family: var(--primary_font_family);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-left: 4px;
}

.c-audit-results-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;
    margin-top: 40px;
}

.c-audit-results-card {
    align-items: center;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.06);
    box-shadow: 0 1px 4px rgba(100, 100, 100, 0.02), 0 1px 4px rgba(100, 100, 100, 0.03);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 188px;
    min-width: 236px;
    max-width: 236px;
    padding: 12px 12px 0 12px;
    position: relative;
    -webkit-animation: showCard 0.3s forwards;
    animation: showCard 0.3s forwards;
    opacity: 0;
}


@-webkit-keyframes showCard {
      0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes showCard {
      0% { opacity: 0; }
     100% { opacity: 1; }
}


.c-audit-results-card__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
}

.c-audit-results-card__title {
    color: var(--black);
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 500;
}

.c-audit-results-card__subtitle {
    top: 0;
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 12px;
    font-weight: 400;
    margin-top: 34px;
    letter-spacing: 0.5px;
    margin-left: 12px;
    position: absolute;
    line-height: 14px;
    text-align: left;
    width: 100%;
}

.c-audit-results-card__samples {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.c-audit-results-card__sample {
    align-items: center;
    background-color: var(--light_grey);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 105px;
    width: 105px;
}

.c-audit-results-card__sample--real {
    margin-right: 2px;
}

.c-audit-results-card__sample--fake {
    margin-left: 2px;
}

.c-audit-results-card__sample-size {
    font-family: var(--primary_font_family);
    font-size: 34px;
    font-weight: bold;
}

.c-audit-results-card__sample-size--real {
    color: var(--black);
}

.c-audit-results-card__sample-size--fake {
    color: var(--red);
}

.c-audit-results-card__sample-label {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
}

.c-audit-results-card--table {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.c-audit-results-card__row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
}

.c-audit-results-card__row:nth-child(2) {
    background-color: var(--light_grey);
}

.c-audit-results-card__stat-title {
    color: var(--black);
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 500;
}

.c-audit-results-card__stat-label {
    color: var(--dark_grey);
    font-family: var(--secondary_font_family);
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
}

.c-audit-results-card__stat-value {
    font-family: var(--primary_font_family);
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
}

.c-audit-results-card__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    height: 188px;
    min-width: 236px;
    max-width: 236px;
}

@media only screen and (max-width: 1200px) { 

    .c-audit-results-container {
        justify-content: flex-start;
    }

    .c-audit-results-card__container {
        margin-bottom: 32px;
        margin-right: 32px;
    }

    .c-audit-results-card {
        margin-bottom: 32px;
        margin-right: 32px;
    }
}

@media only screen and (max-width: 655px) { 

    .c-audit-results-card__container {
        margin-bottom: 32px;
    }

    .c-audit-results-card__container:nth-child(2) {
        margin-bottom: 0px;
    }

    .c-audit-results-card  {
        margin-bottom: 32px;
    }

    .c-audit-results-card:nth-child(2) {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 584px) { 

    .c-audit-results-container {
        justify-content: center;
    }

    .c-audit-results-card__container {
        margin-left: 32px;
        margin-right: 32px;
    }

    .c-audit-results-card__container:nth-child(2) {
        margin-right: 32px;
    }

    .c-audit-results-card {
        margin-left: 32px;
        margin-right: 32px;
    }

    .c-audit-results-card:nth-child(2) {
        margin-right: 32px;
    }
}
.c-audit-status-container {
    background-color: var(--bg_color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c-audit-status {
    height: 0;
    position: relative;
    -webkit-animation: grow 0.25s  forwards;
    animation: grow 0.25s forwards;
    opacity: 0;
    z-index: 500;
}

.c-audit-status__divider {
    background-color: white;
    border: none;
    color: white;
    height: 3px;
    outline: none;
}

.c-audit-status--fullpage {
    -webkit-animation: fullPage 0.25s forwards;
    animation: fullPage 0.25s forwards;
}

.c-audit-status--hidden {
    height: calc(50vh - 45px);
    -webkit-animation: shrink 0.25s forwards;
    animation: shrink 0.25s forwards;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
    opacity: 1;
}

@-webkit-keyframes grow {
      0% { height: 0px; opacity: 0; }
    100% { height: calc(50vh - 45px); opacity: 1; }
}

@keyframes grow {
      0% { height: 0px; opacity: 0; }
     100% { height: calc(50vh - 45px); opacity: 1; }
}

@-webkit-keyframes shrink {
      0% { opacity: 1; }
    100% { height: 0px; opacity: 0; }
}

@keyframes shrink {
      0% { opacity: 1; }
     100% { height: 0px; opacity: 0; }
}

@-webkit-keyframes fullPage {
     0% { height: calc(50vh - 45px); opacity: 1; }
    100% { height: calc(100vh - 190px); opacity: 1; }
}

@keyframes fullPage {
     0% { height: calc(50vh - 90px); opacity: 1; }
     100% { height: calc(100vh - 190px); opacity: 1; }
}

.c-audit-status__loader {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 100px !important;
    min-height: 100px !important;
    max-height: 100px !important;
    width: 100%;
    z-index: 1000;
}

.c-audit-status__spinner {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    width: 25px;
}


.c-audit-status__spinner * {
    margin-left: auto !important;
    margin-right: auto !important;
}


.c-audit-status__title {
    color: var(--blue);
    font-family: var(--secondary_font_family);
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 12px;
    text-align: center;
}

.c-audit-status__detail {
    color: #5A5B6D;
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 35px;
    text-align: center;
    z-index: 501;
}

.c-audit-status__icon--private {
    margin-left: 6px;
    padding-top: 3px;
}

.c-ellipse {
    font-family: var(--secondary_font_family);
    font-size: 17px;
    font-weight: 600;
}

.c-ellipse--one {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.0s;
    animation: dot 1.3s infinite;
    animation-delay: 0.0s;
}

.c-ellipse--two {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.2s;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
}

.c-ellipse--three {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.3s;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
}

@-webkit-keyframes dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}

@media only screen and (max-width: 584px) { 

    .c-audit-status-container {
        padding-top: 20px;
    }

    .c-audit-status {
        height: 150px;
    }

    .c-audit-status--hidden {
        height: calc(30vh - 45px);
        -webkit-animation: shrink 0.25s forwards;
        animation: shrink 0.25s forwards;
        -webkit-animation-delay: 1.5s;
                animation-delay: 1.5s;
        opacity: 1;
    }

    @-webkit-keyframes grow {
          0% { height: 0px; opacity: 0; }
        100% { height: calc(30vh - 45px); opacity: 1; }
    }

    @keyframes grow {
          0% { height: 0px; opacity: 0; }
         100% { height: calc(30vh - 45px); opacity: 1; }
    }

    @-webkit-keyframes shrink {
          0% { opacity: 1; }
        100% { height: 0px; opacity: 0; }
    }

    @keyframes shrink {
          0% { opacity: 1; }
         100% { height: 0px; opacity: 0; }
    }

    @-webkit-keyframes fullPage {
         0% { height: calc(53vh - 45px); opacity: 1; }
        100% { height: calc(100vh - 190px); opacity: 1; }
    }

    @keyframes fullPage {
         0% { height: calc(30vh - 90px); opacity: 1; }
         100% { height: calc(100vh - 190px); opacity: 1; }
    }
}
.c-audit-data-container {
    padding-bottom: 94px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1040px;
    min-height: calc(100vh - 90px);
}
/* Style the accordion section */
.c-accordion__section {
  display: flex;
  flex-direction: column;
}

.c-accordion__section * {
    text-align: left !important;
}

/* Style the buttons that are used to open and close the accordion panel */
.c-accordion__button {
  background-color: black;
  color: var(--white);
  cursor: pointer;
  padding-bottom: 18px;
  padding-top: 18px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  -webkit-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
  width: 100%;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.c-accordion__button:hover,
.c-accordion--active {
  background-color: black;
}

.c-accordion__button:hover .c-accordion__title,
.c-accordion__button:hover .c-accordion__icon {
    color: var(--yellow);
}

.c-accordion--active > .c-accordion__title {
    color: var(--yellow);
}

/* Style the accordion content title */
.c-accordion__title {
  color: var(--white);
  font-family: var(--primary_font_family);
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.5px;
}

/* Style the accordion chevron icon */
.c-accordion__icon {
  color: var(--white);
  font-size: 20px;
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

/* Style to rotate icon when state is active */
.c-rotate {
  color: var(--yellow) !important;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.c-accordion__content {
  background-color: black;
  color: #777;
  font-family: var(--secondary_font_family);
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  overflow: hidden;
  -webkit-transition: max-height 0.25s ease, opacity 0.45s ease;
  transition: max-height 0.25s ease, opacity 0.45s ease;
}

.c-accordion__content a {
  color: #777;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-top: 8px;
  margin-bottom: 18px;
  text-decoration: underline;
  width: 90%;
}

.c-accordion__content a:hover {
    color: var(--blue);
}

/* Style the accordion content text */
.c-accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-top: 8px;
  margin-bottom: 18px;
  width: 90%;
}
.c-faq__title {
    color: #777;
    font-family: var(--secondary_font_family);
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 26px;
}

.c-faq__whatis {
  color: var(--white);
  font-family: var(--primary_font_family);
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 26px;
  margin-bottom: 48px;
}

.c-faq {
    background-color: black;
    color: var(--white);
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    max-width: 800px;
    width: 100%;
}
.c-sticky-cover {
    background-color: var(--bg_color);
    height: 4px;
    position: -webkit-sticky;
    position: sticky;
    margin: -1px;
    top: 86px;
    width: 100%;
    z-index: 3000;
}

@media only screen and (max-width: 975px) { 

    .c-sticky-cover {
        margin-top: -1px;
        top: 92px;
    }
}

@media only screen and (max-width: 726px) { 

    .c-sticky-cover {
        margin-top: -1px;
        top: 86px;
    }
}
.c-alert-template__container {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(100, 100, 100, 0.04), 0 2px 5px rgba(100, 100, 100, 0.05);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    margin-bottom: 20px;
    min-width: 180px !important;
}

.c-alert-template__message {
    color: var(--white);
    margin-left: 6px;
    margin-right: 12px;
}

.c-alert-template__icon {
    margin-left: 12px;
}

.c-alert-template__icon--info {
    color: var(--yellow);
}

.c-alert-template__icon--success {
    color: var(--green);
}

.c-alert-template__icon--error {
    color: var(--red);
}

@media only screen and (max-width: 1075px) { 

    .c-alert-template__container {
        margin-bottom: 90px;
    }
}
.c-search-bar-form {
    max-width: 450px;
    margin-left: 12px;
    width: 100%;
}

.c-search-bar-form__group {
    width: 100%;
}

.c-search-bar-form__container {
    position: relative;
}

.c-search-bar-form__input {
    border-radius: 24px;
    border: 1px solid var(--input_border_color_inactive);
    font-family: var(--secondary_font_family);
    font-size: 16px;
    font-weight: 400;
    height: 44px;
    line-height: 44px;
    outline: none;
    padding-left: 34px;
    padding-right: 70px;
    vertical-align: middle;
    width: 100%;
}

.c-search-bar-form__input::-webkit-input-placeholder {
    color: var(--input_placeholder_color);
    font-family: var(--secondary_font_family);
    font-weight: 400;
    outline: none;
}

.c-search-bar-form__input::-moz-placeholder {
    color: var(--input_placeholder_color);
    font-family: var(--secondary_font_family);
    font-weight: 400;
    outline: none;
}

.c-search-bar-form__input:-ms-input-placeholder {
    color: var(--input_placeholder_color);
    font-family: var(--secondary_font_family);
    font-weight: 400;
    outline: none;
}

.c-search-bar-form__input::-ms-input-placeholder {
    color: var(--input_placeholder_color);
    font-family: var(--secondary_font_family);
    font-weight: 400;
    outline: none;
}

.c-search-bar-form__input::placeholder {
    color: var(--input_placeholder_color);
    font-family: var(--secondary_font_family);
    font-weight: 400;
    outline: none;
}

.c-search-bar-form__input:active,
.c-search-bar-form__input:focus,
.c-search-bar-form__input:hover {
    border: 1px solid var(--blue);
    outline: none;
}

.c-search-bar-form__button {
    background-color: white;
    border: none;
    border-radius: 19px;
    color: white !important;
    height: 36px;
    outline: none;
    position: absolute;
    right: 4px;
    top: 4px;
    -webkit-transition: background-color 0.15s ease;
    transition: background-color 0.15s ease;
    width: 36px;
}

.c-search-bar-form__button--invalid {
    background-color: var(--grey);
}

.c-search-bar-form__button--valid {
    background-color: var(--blue) !important;
}

.c-search-bar-form__button:hover {
    cursor: pointer;
}

.c-search-bar-form__icon--search {
    color: white;
    position: absolute;
    right: 10px;
    top: 10px;
}

.c-search-bar-form__icon--at {
    color: var(--input_placeholder_color);
    font-family: sans-serif;
    font-size: 18px;
    line-height: 44px;
    position: absolute;
    left: 14px;
    top: 0px;
    vertical-align: middle;
}

.c-search-bar-form__input:active ~ .c-search-bar-form__icon--at,
.c-search-bar-form__input:focus ~ .c-search-bar-form__icon--at,
.c-search-bar-form__input:hover ~ .c-search-bar-form__icon--at {
    color: var(--blue) !important;
}



.c-search-bar-form__icon--valid,
.c-search-bar-form__icon--invalid {
    position: absolute;
    right: 40px;
    top: 13px;
    width: 28px;
}

.c-search-bar-form__icon-container {
    display: none;
}

.c-search-bar-form__input:active ~ .c-search-bar-form__icon-container,
.c-search-bar-form__input:focus ~ .c-search-bar-form__icon-container {
    display: block !important;
}

.c-search-bar-form__icon--valid {
    color: var(--green) !important;
}

.c-search-bar-form__icon--invalid {
    color: var(--red) !important;
}

@media only screen and (max-width: 726px) {
    .c-search-bar-form {
        margin-left: 0;
    }
}
.c-main-nav-container {
    background-color: var(--bg_color);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 3px rgba(100, 100, 100, 0.02), 0 1px 3px rgba(100, 100, 100, 0.03);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2000;
}

.c-main-nav {
    align-items: center;
    background-color: var(--bg_color);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 90px;
    justify-content: space-between;
}

.c-main-nav__section {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.c-main-nav__section--left {
    flex-grow: 1;
    justify-content: flex-start;
}

.c-main-nav__section--right {
    flex-grow: 0;
    justify-content: flex-end;
}

.c-main-nav__section--right .c-main-nav__item {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 2rem;
    -webkit-transition: color 0.25s ease, -webkit-transform 0.25s ease;
    transition: color 0.25s ease, -webkit-transform 0.25s ease;
    transition: transform 0.25s ease, color 0.25s ease;
    transition: transform 0.25s ease, color 0.25s ease, -webkit-transform 0.25s ease;
}

.c-main-nav__img {
    height: 40px;
    line-height: 35px;
    margin-right: 20px;
    vertical-align: middle;
}

.c-main-nav__icon {
    color: black;
    font-family: var(--secondary_font_family);
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
    text-decoration: none;
}

.c-main-nav__icon--close {
    color: var(--red);
}

.c-main-nav__button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-family: var(--secondary_font_family);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    outline: none;
    text-decoration: none;
}

.c-main-nav__button--close span {
    line-height: 20px;
    padding-top: 2px;
}

.c-main-nav__button:hover span {
    color: var(--yellow);
}

.c-main-nav__button--close,
.c-main-nav__button--close span {
    color: var(--white);
}

..c-main-nav__button--close span {}

.c-main-nav__button--close:hover span {
    color: var(--red) !important;
}

@media only screen and (max-width: 1075px) {

    .c-main-nav {
        justify-content: center;
    }

    .c-main-nav__section {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .c-main-nav__section--left {
        flex-basis: 100%;
        justify-content: center;
    }

    .c-main-nav__img {
        margin-bottom: 2rem;
        margin-top: 2rem;
        margin-right: 0;
        flex-basis: 100%;
    }

    .c-main-nav__section--right {
        background-color: var(--blue);
        height: 70px;
        order: 0;
        flex-basis: 100%;
        justify-content: center;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .c-main-nav__button {
        color: var(--white);
        height: 70px;
        width: 100%;
    }

    .c-main-nav__section--right .c-main-nav__item {
        margin-left: 0;
    }

    .c-main-nav__icon {
        color: var(--white);
    }
}

@media only screen and (max-width: 736px) { 

    .c-main-nav-container {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
    }
}

@media only screen and (max-width: 726px) { 

    .c-main-nav-container {
        position: -webkit-sticky;
        position: sticky;
        top: -75px;
    }

    .c-main-nav__img {
        margin-right: 0px;
        flex-basis: 100%;
    }

    .c-main-nav {
        padding-bottom: 25px;
    }
}
.c-footer {
    align-items: center;
    background-color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100px;
    justify-content: space-between;
}

.c-footer * {
    color: white;
    border-bottom: none;
    font-family: var(--secondary_font_family);
    outline: none;
}

.c-footer__section {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.c-footer__section--left {
    justify-content: flex-start;
}

.c-footer__section--right {
    justify-content: flex-end;
}

.c-footer__subsection--dsp {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
    justify-content: center;
}

.c-footer__subsection--dsp:hover {
    display: flex;
}

.c-footer__copyright {
    margin-bottom: 0px;
}

.c-footer__dsp-title {
    display: block;
    line-height: 40px;
    margin-left: 8px;
    -webkit-text-decoration-color: var(--blue) !important;
            text-decoration-color: var(--blue) !important;
}

.c-footer__dsp-title:active, 
.c-footer__dsp-title:hover {
    -webkit-text-decoration-color: var(--blue) !important;
            text-decoration-color: var(--blue) !important;
}

.c-footer__dsp-logo {
    height: 18px;
    width: 18px;
}

.c-footer-menu__links {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 2rem;
    width: 100%;
}

.c-footer__subsection--insta,
.c-footer__subsection--contact {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.c-footer__subsection--contact {
    margin-left: 2rem;
}

.c-footer__subsection--insta:hover,
.c-footer__subsection--contact:hover {
    display: flex;
}

.c-footer__insta-title {
    display: block;
    line-height: 40px;
    margin-left: 8px;
    -webkit-text-decoration-color: var(--blue) !important;
            text-decoration-color: var(--blue) !important;
}

.c-footer__insta-title:active, 
.c-footer__insta-title:hover {
    -webkit-text-decoration-color: var(--blue) !important;
            text-decoration-color: var(--blue) !important;
}

.c-footer__insta-logo,
.c-footer__contact-logo {
    color: white;
    height: 18px;
    margin-right: 8px;
    width: 18px;
}


@media only screen and (max-width: 975px) {

    .c-footer {
        align-items: center;
        justify-content: center;
        padding-bottom: 70px;
    }

    .c-footer__section--left {
        align-items: center;
        justify-content: center;
        flex-basis: 100%;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .c-footer__section--right {
        align-items: center;
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }

    .c-footer-menu__links {
        flex-basis: 100%;
        margin-left: 0;
        margin-top: 2rem;
    }

    .c-footer__subsection--dsp {
        margin-left: 0;
    }

    .c-footer__subsection--dsp {
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .c-footer__subsection--contact {
        display: flex;
    }
}

