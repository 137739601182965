.c-audit-status-container {
    background-color: var(--bg_color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c-audit-status {
    height: 0;
    position: relative;
    -webkit-animation: grow 0.25s  forwards;
    animation: grow 0.25s forwards;
    opacity: 0;
    z-index: 500;
}

.c-audit-status__divider {
    background-color: white;
    border: none;
    color: white;
    height: 3px;
    outline: none;
}

.c-audit-status--fullpage {
    -webkit-animation: fullPage 0.25s forwards;
    animation: fullPage 0.25s forwards;
}

.c-audit-status--hidden {
    height: calc(50vh - 45px);
    -webkit-animation: shrink 0.25s forwards;
    animation: shrink 0.25s forwards;
    animation-delay: 1.5s;
    opacity: 1;
}

@-webkit-keyframes grow {
      0% { height: 0px; opacity: 0; }
    100% { height: calc(50vh - 45px); opacity: 1; }
}

@keyframes grow {
      0% { height: 0px; opacity: 0; }
     100% { height: calc(50vh - 45px); opacity: 1; }
}

@-webkit-keyframes shrink {
      0% { opacity: 1; }
    100% { height: 0px; opacity: 0; }
}

@keyframes shrink {
      0% { opacity: 1; }
     100% { height: 0px; opacity: 0; }
}

@-webkit-keyframes fullPage {
     0% { height: calc(50vh - 45px); opacity: 1; }
    100% { height: calc(100vh - 190px); opacity: 1; }
}

@keyframes fullPage {
     0% { height: calc(50vh - 90px); opacity: 1; }
     100% { height: calc(100vh - 190px); opacity: 1; }
}

.c-audit-status__loader {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 100px !important;
    min-height: 100px !important;
    max-height: 100px !important;
    width: 100%;
    z-index: 1000;
}

.c-audit-status__spinner {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    width: 25px;
}


.c-audit-status__spinner * {
    margin-left: auto !important;
    margin-right: auto !important;
}


.c-audit-status__title {
    color: var(--blue);
    font-family: var(--secondary_font_family);
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 12px;
    text-align: center;
}

.c-audit-status__detail {
    color: #5A5B6D;
    font-family: var(--secondary_font_family);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 35px;
    text-align: center;
    z-index: 501;
}

.c-audit-status__icon--private {
    margin-left: 6px;
    padding-top: 3px;
}

.c-ellipse {
    font-family: var(--secondary_font_family);
    font-size: 17px;
    font-weight: 600;
}

.c-ellipse--one {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.0s;
    animation: dot 1.3s infinite;
    animation-delay: 0.0s;
}

.c-ellipse--two {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.2s;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
}

.c-ellipse--three {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.3s;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
}

@-webkit-keyframes dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}

@media only screen and (max-width: 584px) { 

    .c-audit-status-container {
        padding-top: 20px;
    }

    .c-audit-status {
        height: 150px;
    }

    .c-audit-status--hidden {
        height: calc(30vh - 45px);
        -webkit-animation: shrink 0.25s forwards;
        animation: shrink 0.25s forwards;
        animation-delay: 1.5s;
        opacity: 1;
    }

    @-webkit-keyframes grow {
          0% { height: 0px; opacity: 0; }
        100% { height: calc(30vh - 45px); opacity: 1; }
    }

    @keyframes grow {
          0% { height: 0px; opacity: 0; }
         100% { height: calc(30vh - 45px); opacity: 1; }
    }

    @-webkit-keyframes shrink {
          0% { opacity: 1; }
        100% { height: 0px; opacity: 0; }
    }

    @keyframes shrink {
          0% { opacity: 1; }
         100% { height: 0px; opacity: 0; }
    }

    @-webkit-keyframes fullPage {
         0% { height: calc(53vh - 45px); opacity: 1; }
        100% { height: calc(100vh - 190px); opacity: 1; }
    }

    @keyframes fullPage {
         0% { height: calc(30vh - 90px); opacity: 1; }
         100% { height: calc(100vh - 190px); opacity: 1; }
    }
}