.c-alert-template__container {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(100, 100, 100, 0.04), 0 2px 5px rgba(100, 100, 100, 0.05);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    margin-bottom: 20px;
    min-width: 180px !important;
}

.c-alert-template__message {
    color: var(--white);
    margin-left: 6px;
    margin-right: 12px;
}

.c-alert-template__icon {
    margin-left: 12px;
}

.c-alert-template__icon--info {
    color: var(--yellow);
}

.c-alert-template__icon--success {
    color: var(--green);
}

.c-alert-template__icon--error {
    color: var(--red);
}

@media only screen and (max-width: 1075px) { 

    .c-alert-template__container {
        margin-bottom: 90px;
    }
}