.c-page {
    background-color: var(--bg_color);
    margin-top: -4px;
}

.c-page--audit {
    min-height: calc(100vh - 90px);
    width: 100%;
    z-index: 1000;
}

.c-page--index {
    min-height: calc(100vh - 90px - 42px);
    height: auto;
    padding-top: 4px;
    width: 100%;
}

.c-page--faq {
    background-color: black;
    min-height: calc(100vh - 90px);
    width: 100%;
}

.c-page--index > .l-container {
    align-items: flex-start;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.c-page--loading {
    align-items: center;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 90px);
    padding-bottom: 25%;
    width: 100%;
    z-index: 1000;
}

@media only screen and (max-width: 1100px) {
    .c-page--index {
        min-height: calc(100vh + 400px);
    }
    .c-page--index > .l-container {
        min-height: 0;
    }
}

