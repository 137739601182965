/* Style the accordion section */
.c-accordion__section {
  display: flex;
  flex-direction: column;
}

.c-accordion__section * {
    text-align: left !important;
}

/* Style the buttons that are used to open and close the accordion panel */
.c-accordion__button {
  background-color: black;
  color: var(--white);
  cursor: pointer;
  padding-bottom: 18px;
  padding-top: 18px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  width: 100%;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.c-accordion__button:hover,
.c-accordion--active {
  background-color: black;
}

.c-accordion__button:hover .c-accordion__title,
.c-accordion__button:hover .c-accordion__icon {
    color: var(--yellow);
}

.c-accordion--active > .c-accordion__title {
    color: var(--yellow);
}

/* Style the accordion content title */
.c-accordion__title {
  color: var(--white);
  font-family: var(--primary_font_family);
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.5px;
}

/* Style the accordion chevron icon */
.c-accordion__icon {
  color: var(--white);
  font-size: 20px;
  transition: transform 0.25s ease;
}

/* Style to rotate icon when state is active */
.c-rotate {
  color: var(--yellow) !important;
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.c-accordion__content {
  background-color: black;
  color: #777;
  font-family: var(--secondary_font_family);
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  overflow: hidden;
  transition: max-height 0.25s ease, opacity 0.45s ease;
}

.c-accordion__content a {
  color: #777;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-top: 8px;
  margin-bottom: 18px;
  text-decoration: underline;
  width: 90%;
}

.c-accordion__content a:hover {
    color: var(--blue);
}

/* Style the accordion content text */
.c-accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-top: 8px;
  margin-bottom: 18px;
  width: 90%;
}