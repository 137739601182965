.c-faq__title {
    color: #777;
    font-family: var(--secondary_font_family);
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 26px;
}

.c-faq__whatis {
  color: var(--white);
  font-family: var(--primary_font_family);
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 26px;
  margin-bottom: 48px;
}

.c-faq {
    background-color: black;
    color: var(--white);
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    max-width: 800px;
    width: 100%;
}