.c-pie-chart-container {
    position: relative;
    margin-bottom: 10px !important;
}

.c-pie-chart__value-container {
    align-items: center;
    bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 100%;
}

.c-pie-chart__value {
    font-family: var(--primary_font_family);
    font-size: 60px;
    font-weight: bold;
    margin-left: 8px;
}

.c-pie-chart__percent {
    font-family: var(--primary_font_family);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-left: 4px;
}
