.c-share-button-container {
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--blue);
    box-shadow: 0 2px 5px rgba(100, 100, 100, 0.04), 0 2px 5px rgba(100, 100, 100, 0.05);
    height: 78px;
    margin-left: 12px;
    width: 85px;
}

.c-share-button-container:hover {
    border-color: var(--black);
}

.c-share-button {
    background-color: var(--white);
    border-radius: 8px;
    border: none;
    color: var(--blue);
    cursor: pointer;
    height: 100%;
    outline: none;
    padding-top: 4px;
    width: 100%;
}

.c-share-button:hover {
    border-color: black;
    color: var(--black);
}

.c-share-button:hover * {
    color: var(--black);
}

.c-share-button__inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.c-share-button__title {
    color: var(--blue);
    font-family: var(--secondary_font_family);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 10px;
}

@media only screen and (max-width: 520px) { 

    .c-share-button-container {
        flex-basis: 100%;
        margin-left: 0px; 
        margin-right: 0px; 
        margin-top: 32px; 
        max-width: 300px;
    }
}