.c-loading-page__title {
    align-items: center;
    color: var(--blue);
    display: flex;
    flex-direction: row;
    font-family: var(--secondary_font_family);
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 35px;
    text-align: center;
    justify-content: center;
    padding-bottom: 25%;
}

.c-loading-page__title * {
    color: var(--blue);
    font-family: var(--secondary_font_family);
    font-size: 17px;
    font-weight: 600;
}