/* Base */

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700%7CRoboto:300,400,500,700&display=swap');

:root {
    --black: #2E3A49;
    --blue: #0029FF;
    --red: #E4373F;
    --orange: #FF7702;
    --yellow: #FEDA01;
    --green: #01C58E;
    --grey: #DFE1E5;
    --dark_grey: #47494C;
    --light_grey: #FAFAFA;
    --medium_grey: #B6BAB9;
    --white: #FFF;
    --bg_color: var(--light_grey);
    --accent_color: black;
    --primary_text_color: var(--black);
    --accent_text_color: blue;
    --primary_font_size: 14px;
    --primary_font_family: 'Roboto Condensed', sans-serif;
    --secondary_font_family: 'Roboto', sans-serif;
    --input_placeholder_color: #979797;
    --input_border_color_inactive: #DFE1E5;
    --input_border_color_active: #F1F3F7;
}

/* Overrides */

* {
    box-sizing: border-box !important;
}

*::-webkit-scrollbar { width: 0 !important }

input[type=text],
input[type=username] {   
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

html,
body {
    background-color: black;
}

a {
    border-bottom: none;
    cursor: pointer;
    display: block;
}

a:active,
a:hover {
    background-color: transparent !important;
    border-bottom: none;
    color: var(--blue);
    display: block;
    text-decoration: underline !important;
}

a, p, h1, h2, h3, h4, h5, h6, span, input {
    color: var(--primary_text_color);
    font-family: var(--primary_font_family);
    font-size: var(--primary_font_size);
    font-weight: 400;
    margin: 0;
}

/* Container */

.l-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding-left: 80px;
    padding-right: 80px;
}

.l-container--inner {
    max-width: 1040px;
}

/* Misc */

.animation-delay-half-sec {
    animation-delay: 0.5s !important;
}

.animation-delay-one-sec {
    animation-delay: 1s !important;
}

.animation-delay-one-and-half-sec {
    animation-delay: 1.5s !important;
}

.background-color--black {
    background-color: black !important;
}

.background-color--bg-color {
    background-color: var(--bg_color) !important;
}


.background-color--green {
    background-color: var(--green) !important;
}

.background-color--yellow {
    background-color: var(--yellow) !important;
}

.background-color--orange {
    background-color: var(--orange) !important;
}

.background-color--red {
    background-color: var(--red) !important;
}

.background-color--white {
    background-color: var(--white) !important;
}

.bold {
    font-weight: bold !important;
    letter-spacing: 0.5px;
}

.color-red {
    color: var(--red) !important;
}

.color-green {
    color: var(--green);
}

.color-insta-verified {
    color: rgb(55, 155, 235);
}

.color-red {
    color: var(--red);
}

.hidden {
    display: none;
}

.c-tooltip span {
    color: white !important;
    font-family: var(--secondary_font_family) !important;
    font-size: 14px;
    font-weight: 300;
}

.c-exclamation-circle {
    color: var(--red);
}

.c-info-circle {
    color: var(--grey);
}

.c-info-circle:hover {
    color: var(--black);
}

.c-check-circle {
    color: var(--green);
}

.c-times-circle {
    color: var(--red);
}

.c-lock {
    color: var(--black) !important;
}

.c-minus {
    color: var(--grey);
}

.c-verified {
    height: 18px;
    transform: scale(0.0);
    width: 18px;
}

.c-real {
    color: var(--green);
    height: 18px;
    transform: scale(0.0);
    width: 18px;
}

.c-fake {
    color: var(--red);
    height: 18px;
    transform: scale(0.0);
    width: 18px;
}

.c-position--relative {
    position: relative !important;
}

.c-position--relative {
    position: relative !important;
}

.c-top--zero {
    top: 0 !important;
}

.c-padding-bottom--zero {
    padding-bottom: 0 !important;
}

.ticker {
    width: 100%;
}

/* Animations */


.pulse-on-display {
  animation: pulse 0.3s ease-in forwards;
  animation-fill-mode: forward;
}

@keyframes pulse {
    0%  {transform: scale(0.0);}
   40%  {transform: scale(0.8);}
   75%  {transform: scale(1.3);}
  100%  {transform: scale(1);}
}

/* Media Queries*/

@media only screen and (max-width: 900px) {
    .l-container {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media only screen and (max-width: 656px) {
    .l-container {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media only screen and (max-width: 400px) {
    .l-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}
