.c-footer {
    align-items: center;
    background-color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100px;
    justify-content: space-between;
}

.c-footer * {
    color: white;
    border-bottom: none;
    font-family: var(--secondary_font_family);
    outline: none;
}

.c-footer__section {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.c-footer__section--left {
    justify-content: flex-start;
}

.c-footer__section--right {
    justify-content: flex-end;
}

.c-footer__subsection--dsp {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
    justify-content: center;
}

.c-footer__subsection--dsp:hover {
    display: flex;
}

.c-footer__copyright {
    margin-bottom: 0px;
}

.c-footer__dsp-title {
    display: block;
    line-height: 40px;
    margin-left: 8px;
    text-decoration-color: var(--blue) !important;
}

.c-footer__dsp-title:active, 
.c-footer__dsp-title:hover {
    text-decoration-color: var(--blue) !important;
}

.c-footer__dsp-logo {
    height: 18px;
    width: 18px;
}

.c-footer-menu__links {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 2rem;
    width: 100%;
}

.c-footer__subsection--insta,
.c-footer__subsection--contact {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.c-footer__subsection--contact {
    margin-left: 2rem;
}

.c-footer__subsection--insta:hover,
.c-footer__subsection--contact:hover {
    display: flex;
}

.c-footer__insta-title {
    display: block;
    line-height: 40px;
    margin-left: 8px;
    text-decoration-color: var(--blue) !important;
}

.c-footer__insta-title:active, 
.c-footer__insta-title:hover {
    text-decoration-color: var(--blue) !important;
}

.c-footer__insta-logo,
.c-footer__contact-logo {
    color: white;
    height: 18px;
    margin-right: 8px;
    width: 18px;
}


@media only screen and (max-width: 975px) {

    .c-footer {
        align-items: center;
        justify-content: center;
        padding-bottom: 70px;
    }

    .c-footer__section--left {
        align-items: center;
        justify-content: center;
        flex-basis: 100%;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .c-footer__section--right {
        align-items: center;
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }

    .c-footer-menu__links {
        flex-basis: 100%;
        margin-left: 0;
        margin-top: 2rem;
    }

    .c-footer__subsection--dsp {
        margin-left: 0;
    }

    .c-footer__subsection--dsp {
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .c-footer__subsection--contact {
        display: flex;
    }
}
